<template>
  <div>
    <div class="content-header justify-content-between">
      <div>
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="#">Pages</a>
            </li>
            <li class="breadcrumb-item">
              <a href="#">Dashboard</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">Analytics &amp; Monitoring</li>
          </ol>
        </nav>
        <h4 class="content-title content-title-xs">Welcome to Dashboard</h4>
      </div>
    </div>
    <div class="content-body">
      <div class="row row-sm">
        <div class="col-xl-12">
          <div class="card card-hover card-analytics-one">
            <div class="card-body">
              <b-button @click="setMessage">Alert</b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["messageState", "eventMessage"]),
  },
  methods: {
    setMessage() {
      this.$store.dispatch("enableMessage");
      this.$store.commit(
        "setEventMessage",
        "This is the content of the toast. It is short and to the point."
      );
    },
  },
};
</script>